<template>
  <UiRow no-gutters class="app-navbar-menu">
    <template v-for="button in appNavbarButtons">
      <UiCol v-if="button.visible" :key="button.id" cols="auto">
        <AppNavbarButton
          :label="button.label"
          :badge="button.count"
          :prepend-icon="button.icon"
          :color="button.color"
          :text="button.text"
          :href="button.href"
          :to="button.to"
        />
      </UiCol>
    </template>
  </UiRow>
</template>

<script setup lang="ts">
import AppNavbarButton from './AppNavbarButton.vue';
import { useAppNavbarMenu } from '~/infrastructure';
import { UiCol, UiRow } from '~/ui';

const { appNavbarButtons } = useAppNavbarMenu();
</script>

<style lang="scss" scoped>
.app-navbar-menu {
  column-gap: 16px;
  flex-grow: 0;
  width: auto;
  flex-wrap: nowrap;
}
</style>
