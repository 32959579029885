import splitArray from '~/utils/array/splitArray';
import { type SidebarItemInner } from '~/ui/UiSidebar/UiSidebarMenuItemInner.vue';
import sidebarService from './sidebar.service';
import {
  type Brand,
  type Category,
  type CategoryAction,
  type CategoryActionResponse,
} from '~/domains/category/types.d';
import {
  type SidebarItem,
  type SidebarActionItem,
} from '~/app/layouts/sidebar';

const CATALOG_PATH = '/catalog';
const BRAND_PATH = '/brands';
const ACTIONS_PATH = '/actions';

const getChildren = (
  data: Category | Brand | CategoryAction,
  depth: number,
): SidebarItem[] | SidebarItem[][] | undefined => {
  const serializedChildren = data?.children?.map((child) => {
    return serializeCategory(child as Category, depth);
  });

  if (depth === 1) {
    return splitArray<SidebarItem>(serializedChildren, 3);
  } else {
    return serializedChildren;
  }
};

const serializeCategory = (data: Category, depth?: number): SidebarItem => {
  if (typeof depth === 'number') depth++;
  else depth = 1;

  return {
    id: String(data.id),
    label: data.name,
    icon: '$catalog',
    link: `${CATALOG_PATH}/${data.slug}`,
    children: getChildren(data, depth),
    additional: data.additional,
  };
};

const serializeCategories = (
  categories: Category[] | undefined,
): SidebarItem[] => {
  return categories?.map((category) => serializeCategory(category)) ?? [];
};

const getCategoryInBrand = (
  brand: Brand,
  category: Category,
): SidebarItem | undefined => {
  if (category.children?.length === 0) return;

  return {
    id: String(category.id),
    label: category.name,
    link: `${BRAND_PATH}/${brand.id}/categories/${category.slug}`,
    children: category.children?.map((child) =>
      getCategoryInBrand(brand, child),
    ),
  };
};

const removeRootPropertyFromBrandsTree = (
  brands: Brand[] | undefined,
): Brand[] | undefined => {
  return brands?.map((brand) => {
    return {
      id: brand.id,
      name: brand.name,
      children: brand.root?.children ?? [],
      additional: brand.root?.additional,
    };
  });
};

const serializeBrand = (brand: Brand, depth?: number): SidebarItemInner => {
  if (typeof depth === 'number') depth++;
  else depth = 1;

  const children = splitArray(
    brand.children?.map((child) =>
      getCategoryInBrand(brand, child as Category),
    ),
    3,
  );

  return {
    id: String(brand.id),
    label: brand.name,
    link: `${BRAND_PATH}/${brand.id}`,
    layout: 'grid',
    children,
    additional: brand.additional,
  };
};

const serializeBrands = (
  brands: Brand[] | undefined,
): SidebarItemInner[] | undefined => {
  return sidebarService.sortItemsInnerByLabel(
    removeRootPropertyFromBrandsTree(brands)?.map((brand: Brand) =>
      serializeBrand(brand),
    ),
  );
};

const getCategoryInActionType = (
  action: CategoryActionResponse,
  category: CategoryAction,
): SidebarItem | undefined => {
  if (category.children?.length === 0) return;

  return {
    id: String(category.id),
    label: category.name,
    link: `${ACTIONS_PATH}/${action.type}/categories/${category.slug}`,
    children: category.children?.map((child) =>
      getCategoryInActionType(action, child),
    ),
  };
};

const serializeCategoryAction = (
  data: CategoryActionResponse | undefined,
): SidebarActionItem => {
  const children = splitArray(
    data?.root?.children?.map((child: CategoryAction) =>
      getCategoryInActionType(data, child),
    ),
    3,
  );

  return {
    id: data?.type ?? '',
    type: data?.type ?? '',
    link: `${ACTIONS_PATH}/${data?.type}`,
    children,
  };
};

const serializeCategoryActions = (
  actions: CategoryActionResponse[] | undefined,
): SidebarActionItem[] => {
  return actions?.map((action) => serializeCategoryAction(action)) ?? [];
};

export {
  serializeCategories,
  serializeBrands,
  serializeCategoryActions,
  serializeCategory,
  getChildren,
  getCategoryInBrand,
  serializeBrand,
  getCategoryInActionType,
  serializeCategoryAction,
};
