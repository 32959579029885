import type { Basket, BasketResponse } from '~/domains/basket';
import {
  basketLastAddedLicenceSerializer,
  basketProductsSerializer,
  basketStatisticsSerializer,
} from '~/domains/basket';

export default function basketSerializer(data: BasketResponse): Basket {
  return {
    details: basketStatisticsSerializer(data.statistics),
    lastAddedLicenseType: basketLastAddedLicenceSerializer(
      data.last_added_license_type,
    ),
    baskets: {
      ...basketProductsSerializer(data.baskets),
    },
  };
}
