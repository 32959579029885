export const BASKETS_TYPE_MAP = {
  basketWithProducts: {
    type: 'NOT_A_LICENSE',
    tabTitle: 'components.basketDataTable.basketByLicenseTitle.products',
  },
  basketWithMarginVatProducts: {
    type: 'NOT_A_LICENSE_MARGINAL_VAT',
    tabTitle: 'components.basketDataTable.tab.marginVat.title',
  },
  basketWithLicenceProducts: {
    type: 'ESD',
    tabTitle: 'components.basketDataTable.basketByLicenseTitle.ESD',
  },
} as const;
