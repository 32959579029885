<template>
  <VDialog
    :model-value="modelValue"
    :width="width"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :eager="eager"
    :content-class="fullscreen ? '' : 'ui-dialog-base__border'"
    :height="actualHeight"
    :max-height="maxHeight"
    class="ui-dialog-base"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props }" />
    </template>
    <slot />
  </VDialog>
</template>
<script setup lang="ts">
import { VDialog } from 'vuetify/components/VDialog';
import { computed } from 'vue';

export interface Props {
  modelValue: boolean;
  width?: string;
  scrollable?: boolean;
  fullscreen?: boolean;
  eager?: boolean;
  height?: string | number;
  maxHeight?: string | number;
}
const {
  width = '500px',
  scrollable = false,
  fullscreen = false,
  eager = false,
  height = undefined,
  maxHeight = undefined,
} = defineProps<Props>();
defineEmits<(e: 'update:modelValue', value: any) => void>();

const actualHeight = computed(() =>
  maxHeight !== undefined ? (height ?? 'calc(100% - 48px)') : height,
);
</script>
