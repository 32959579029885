<template>
  <UiTooltip :disabled="lgAndUp">
    <template #activator="{ props }">
      <UiButtonLabeled
        v-bind="props"
        :label="label"
        :variant="variant"
        :text="text"
        rounded="xl"
        :to="to"
        :href="href"
        :color="color"
        class="navbar-button"
      >
        <template #prepend>
          <UiBadge
            :offset-y="-1"
            :offset-x="-3"
            :content="badge"
            :color="badge ? 'red' : 'transparent'"
          >
            <UiIcon v-if="prependIcon" :icon="prependIcon" size="24" />
          </UiBadge>
        </template>
      </UiButtonLabeled>
    </template>
    <AppTranslate :identifier="label" />
  </UiTooltip>
</template>

<script setup lang="ts">
import {
  UiButtonLabeled,
  UiIcon,
  UiBadge,
  UiTooltip,
  type ButtonVariant,
} from '~/ui';
import { AppTranslate } from '~/infrastructure';
import { useDisplay } from 'vuetify';

export interface Props {
  label: string;
  text?: string;
  prependIcon?: string;
  badge?: string;
  variant?: ButtonVariant;
  to?: string;
  color?: string;
  href?: string;
}

const { variant = 'text' } = defineProps<Props>();
const { lgAndUp } = useDisplay();
</script>

<style lang="scss" scoped>
.navbar-button {
  &.v-btn--active {
    border: thin solid currentColor;
  }
}
</style>
