<template>
  <UiAppBar :elevation="elevation" :style="styles">
    <UiContainer>
      <div class="ui-navbar__inner">
        <slot />
      </div>
    </UiContainer>
  </UiAppBar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiAppBar } from '../UiAppBar';
import UiContainer from '../UiContainer/UiContainer.vue';
import { useRoute, useScroll } from '~/infrastructure/composables';
import { useWindowScroll } from '@vueuse/core';

export interface Props {
  left: string | null;
  width: string | null;
}

const props = defineProps<Props>();

const { y } = useWindowScroll();
const { isScrollingBottom } = useScroll();

const styles = computed(() => {
  return {
    transform: `translateY(${
      isScrollingBottom.value ? 'calc(var(--header-height) * -1)' : '0'
    })`,
    ...(props.left != null ? { left: props.left } : {}),
    ...(props.width != null ? { width: props.width } : {}),
  };
});
const route = useRoute();

const elevation = computed(() => {
  if (route.path === '/compare') return 0;
  return y.value <= 0 ? 0 : 2;
});
</script>

<style lang="scss" scoped>
.ui-navbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  transition: transform var(--layout-transition-duration)
    var(--layout-transition-timing);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
