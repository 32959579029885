<template>
  <VBtn
    :id="id"
    :variant="variant"
    :color="color"
    :size="size"
    :icon="icon"
    :rounded="rounded"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :height="height"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :loading="loading"
    :density="density"
    :to="to"
    :download="download"
    :href="href"
    class="button"
    @click="handleClick"
  >
    <template v-if="$slots['prepend']" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="!icon" #default>
      <slot />
    </template>
    <template v-if="$slots['append']" #append>
      <slot name="append" />
    </template>
  </VBtn>
</template>

<script setup lang="ts">
import { VBtn } from 'vuetify/components/VBtn';
import { type Rounded, type Size } from '~/infrastructure';

export type Variant =
  | 'flat'
  | 'text'
  | 'elevated'
  | 'tonal'
  | 'outlined'
  | 'plain';

export interface Props {
  color?: string;
  variant?: Variant;
  id?: string | null;
  size?: Size;
  icon?: string | null;
  rounded?: Rounded | boolean;
  maxWidth?: string | number | undefined;
  minWidth?: string | number | undefined;
  width?: string | number | undefined;
  height?: string | number | undefined;
  disabled?: boolean;
  prependIcon?: string;
  density?: 'default' | 'comfortable' | 'compact';
  to?: string;
  download?: boolean;
  loading?: boolean;
  href?: string;
}
withDefaults(defineProps<Props>(), {
  color: 'primary',
  variant: 'flat',
  rounded: undefined,
  id: null,
  size: 'default',
  icon: null,
  maxWidth: undefined,
  minWidth: undefined,
  width: undefined,
  height: undefined,
  disabled: false,
  prependIcon: undefined,
  density: 'default',
  to: undefined,
  download: false,
  loading: false,
  href: undefined,
});

const emit = defineEmits<(e: 'click', value: PointerEvent) => void>();

function handleClick(event: PointerEvent): void {
  emit('click', event);
}
</script>

<style scoped lang="scss">
.button.v-btn--size-small {
  --v-btn-size: 32px;
  --v-btn-height: 32px;
  font-size: 14px;
  min-width: inherit;
  padding: 0 8px;
  border-radius: 12px;
}

.button.v-btn--size-default {
  --v-btn-size: 40px;
  --v-btn-height: 40px;
  font-size: 16px;
  min-width: inherit;
  padding: 0 16px;
  border-radius: 16px;
}

.button.v-btn--size-large {
  --v-btn-size: 48px;
  --v-btn-height: 48px;
  font-size: 16px;
  min-width: inherit;
  padding: 0 16px;
  border-radius: 16px;
}

.button.v-btn--icon.v-btn--size-default {
  height: 40px;
  width: 40px;
}

.button.v-btn--icon.v-btn--size-small {
  height: 32px;
  width: 40px;
}

.button.v-btn--icon.v-btn--size-large {
  height: 48px;
  width: 48px;
}
</style>
