<template>
  <UiMenu :model-value="modelValueLocal" @update:model-value="handleChange">
    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props }" />
    </template>
    <UiList class="ui-dropdown__list">
      <slot />
    </UiList>
  </UiMenu>
</template>

<script setup lang="ts">
import UiList from '../UiList/UiList.vue';
import UiMenu from '../UiMenu/UiMenu.vue';

const emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();

const modelValueLocal = defineModel({ default: false, type: Boolean });

const handleChange = (value: boolean): void => {
  modelValueLocal.value = value;
  emit('update:modelValue', value);
};
</script>

<style lang="scss" scoped>
.ui-dropdown__list {
  max-height: 400px;
  padding: 0;
}
</style>
