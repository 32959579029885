import { computed, type ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useBasketStatsStore } from '~/domains/basket';

interface UseBasketButtonReturn {
  amountText: ComputedRef<string>;
  basketCount: ComputedRef<string | undefined>;
}

export default function useBasketButton(): UseBasketButtonReturn {
  const { basketStats, getQuantity } = storeToRefs(useBasketStatsStore());
  const currency = computed(() => basketStats.value?.currency ?? '');

  const amountText: ComputedRef<string> = computed(() =>
    basketStats.value?.amount !== undefined
      ? `${basketStats.value?.amount.toString()} ${currency.value}`
      : '',
  );

  const basketCount = computed(() =>
    getQuantity.value !== '0' ? getQuantity.value : '',
  );

  return {
    amountText,
    basketCount,
  };
}
