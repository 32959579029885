import { type Ref, ref } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('order', () => {
  const lastViewedOrderId: Ref<string | undefined> = ref();
  const isExpandedOrder: Ref<boolean> = ref(false);

  const setExpandedOrder = (value: boolean): void => {
    isExpandedOrder.value = value;
  };

  const setLastViewedOrderId = (id?: string): void => {
    lastViewedOrderId.value = id;
  };

  const resetViewedOrder = (): void => {
    setLastViewedOrderId(undefined);
    setExpandedOrder(false);
  };

  return {
    lastViewedOrderId,
    isExpandedOrder,
    setLastViewedOrderId,
    setExpandedOrder,
    resetViewedOrder,
  };
});
