import { computed, type ComputedRef, watch } from 'vue';
import {
  serializeCategories,
  serializeBrands,
  serializeCategoryActions,
} from '../sidebar.serializer';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import {
  type SidebarItem,
  ORDERS_OPEN_PATH,
  ORDERS_HISTORY_PATH,
} from '~/app/layouts/sidebar';
import { useI18n, usePermissions } from '~/infrastructure';
import { useCategoriesTree } from '~/domains/category';
import useOrderStore from '~/domains/order/stores/useOrderStore.ts';

interface ComposableReturn {
  items: ComputedRef<SidebarItem[]>;
}

export default function useSidebarItems(): ComposableReturn {
  const { categoriesTree, fetchCategoriesTree, macCTOProductTypes } =
    useCategoriesTree();
  const { isEntityHaveAccess } = usePermissions();
  const { user } = storeToRefs(useUserStore());
  const { t } = useI18n();
  const { resetViewedOrder } = useOrderStore();

  const categories = computed(() =>
    serializeCategories(categoriesTree.value?.tree.children),
  );

  const actions = computed(() => {
    const serializedActions = serializeCategoryActions(
      categoriesTree.value?.actions,
    );

    return serializedActions?.map((action) => ({
      ...action,
      label: t(`action.${action.type}.name`),
    }));
  });

  const brands = computed(() => serializeBrands(categoriesTree.value?.brands));
  const ordersActiveCount = computed(
    () =>
      user.value?.ordersOverview?.orders?.find(
        (order) => order.status === 'ACTIVE',
      )?.qty,
  );
  const ordersHistoryCount = computed(
    () =>
      user.value?.ordersOverview?.orders?.find(
        (order) => order.status === 'HISTORY',
      )?.qty,
  );

  const items: ComputedRef<SidebarItem[]> = computed(() => [
    {
      id: 1,
      label: 'common.actions.titles.ROOT.text',
      icon: '$offers',
      searchInput: true,
      color: 'orange',
      isVisible: actions.value?.length > 0,
      children: actions.value,
    },
    {
      id: 2,
      label: 'page.catalog.title',
      icon: '$catalog',
      searchInput: true,
      isVisible: true,
      children: categories.value,
    },
    {
      id: 3,
      label: 'components.serviceCenters.title.tables.Brands',
      icon: '$shop',
      searchInput: true,
      sortByDesc: true,
      isVisible: true,
      children: brands.value,
    },
    {
      id: 4,
      label: 'header.menu.item.macCTO.text',
      icon: '$laptop',
      link: macCTOLink.value,
      isVisible: macCTOLink.value !== null,
    },
    {
      id: 14,
      label: 'header.menu.item.openOrders.text',
      icon: '$orderOpen',
      link: ORDERS_OPEN_PATH,
      isVisible: isEntityHaveAccess('order'),
      badge: ordersActiveCount.value,
      callback: resetViewedOrder,
    },
    {
      id: 15,
      label: 'header.menu.item.ordersHistory.text',
      icon: '$order',
      link: ORDERS_HISTORY_PATH,
      isVisible: isEntityHaveAccess('order'),
      badge: ordersHistoryCount.value,
      callback: resetViewedOrder,
    },
    {
      id: 16,
      label: 'header.menu.item.account.text',
      icon: '$coins',
      link: '/account',
      isVisible: isEntityHaveAccess('account'),
    },
    {
      id: 19,
      label: 'header.menu.item.dep.text',
      icon: '$dep',
      link: '/dep/enrollments',
      isVisible: isEntityHaveAccess('dep'),
    },
    {
      id: 17,
      label: 'page.warranty.title',
      icon: '$warranty',
      link: '/warranty/processing',
      isVisible: isEntityHaveAccess('warranty'),
    },
    {
      id: 18,
      label: 'Favourite',
      icon: '$starOutline',
      disabled: true,
      isVisible: false,
    },
  ]);

  watch(user, (newUser, prevUser) => {
    if (newUser?.selected.language === undefined) return;

    const isNewLang = newUser.selected.language !== prevUser?.selected.language;

    if (isNewLang) fetchCategoriesTree();
  });

  const macCTOLink = computed(() => {
    if (macCTOProductTypes.value === undefined) return null;
    const firstProductType = macCTOProductTypes.value?.[0];

    return `/cto-apple/${firstProductType?.slug}`;
  });

  return {
    items,
  };
}
