<template>
  <VBadge
    ref="uiBadge"
    :content="content"
    :color="color"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :inline="inline"
    :text-color="textColor"
  >
    <slot />
  </VBadge>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { VBadge } from 'vuetify/components/VBadge';

export interface Props {
  content?: string | number;
  color?: string;
  textColor?: string;
  offsetX?: string | number;
  offsetY?: string | number;
  animate?: boolean;
  inline?: boolean;
}

const {
  color = 'primary',
  offsetX = 0,
  offsetY = 0,
  inline = false,
  textColor = undefined,
  content = undefined,
  animate = true,
} = defineProps<Props>();

const uiBadge = ref();
defineExpose({ uiBadge });

function animateBadge(): void {
  uiBadge.value?.$el
    .querySelector('.v-badge__badge')
    ?.animate(
      [
        { transform: 'scale(1)' },
        { transform: 'scale(1.8)' },
        { transform: 'scale(1)' },
      ],
      {
        duration: 300,
        easing: 'ease',
      },
    );
}

watch(
  () => content,
  () => {
    if (!animate) return;
    animateBadge();
  },
);
</script>

<style lang="scss" scoped>
:deep(.v-badge__badge) {
  font-size: 10px;
  height: 18px;
  min-width: 18px;
}
</style>
