import { watch } from 'vue';
import { useAuthStore } from '~/domains/auth';
import { storeToRefs } from 'pinia';

/**
 * Watches for changes in the provided reactive value and invokes the callback
 * only if the user is authenticated.
 *
 * @param getValue - A function returning the reactive value to watch.
 * @param callback - A callback function to be executed when the value changes,
 * but only if the user is authenticated.
 */
export default function useWatchIfAuthenticated(
  getValue: () => any,
  callback: () => void,
): void {
  const { isAuth } = storeToRefs(useAuthStore());

  watch(getValue, () => {
    if (!isAuth.value) return;

    callback();
  });
}
