<template>
  <VMenu
    :model-value="modelValueLocal"
    :location="location"
    :open-on-hover="openOnHover"
    :open-on-focus="openOnFocus"
    :close-on-content-click="closeOnContentClick"
    :disabled="disabled"
    :offset="offset"
    @update:model-value="handleChange"
  >
    <slot />
    <template #activator="data">
      <slot name="activator" v-bind="data"></slot>
    </template>
  </VMenu>
</template>

<script setup lang="ts">
import { VMenu } from 'vuetify/components/VMenu';
import { watch } from 'vue';
import { useRoute } from '~/infrastructure';

type Location = 'bottom' | 'end' | 'top left';

export interface Props {
  location?: Location;
  openOnHover?: boolean;
  closeOnContentClick?: boolean;
  openOnFocus?: boolean;
  disabled?: boolean;
  offset?: string | number | number[];
  modelValue?: boolean;
}

const {
  openOnHover = false,
  location = 'bottom',
  closeOnContentClick = true,
  openOnFocus = false,
  disabled = false,
  offset = undefined,
} = defineProps<Props>();

defineEmits<(e: 'update:modelValue', value: boolean) => void>();

const modelValueLocal = defineModel({ default: false, type: Boolean });
const route = useRoute();

const handleChange = (value: boolean): void => {
  modelValueLocal.value = value;
};

const open = (): void => {
  modelValueLocal.value = true;
};

const close = (): void => {
  modelValueLocal.value = false;
};

watch(
  () => route.path,
  () => {
    close();
  },
);

defineExpose({
  open,
  close,
});
</script>
