import TelegramIcon from '~/ui/UiIcon/icons/TelegramIcon.vue';
import SkypeIcon from '~/ui/UiIcon/icons/SkypeIcon.vue';
import WhatsappIcon from '~/ui/UiIcon/icons/WhatsappIcon.vue';
import PhoneIcon from '~/ui/UiIcon/icons/PhoneIcon.vue';
import MailIcon from '~/ui/UiIcon/icons/MailIcon.vue';
import CatalogIcon from '~/ui/UiIcon/icons/CatalogIcon.vue';
import OrderIcon from '~/ui/UiIcon/icons/OrderIcon.vue';
import AccountIcon from '~/ui/UiIcon/icons/AccountIcon.vue';
import WarrantyIcon from '~/ui/UiIcon/icons/WarrantyIcon.vue';
import StarOutlineIcon from '~/ui/UiIcon/icons/StarOutlineIcon.vue';
import ShopIcon from '~/ui/UiIcon/icons/ShopIcon.vue';
import ChevronRightIcon from '~/ui/UiIcon/icons/ChevronRightIcon.vue';
import ChevronDownIcon from '~/ui/UiIcon/icons/ChevronDownIcon.vue';
import IdCardIcon from '~/ui/UiIcon/icons/IdCardIcon.vue';
import AppleIcon from '~/ui/UiIcon/icons/AppleIcon.vue';
import UsbFlashIcon from '~/ui/UiIcon/icons/UsbFlashIcon.vue';
import LaptopIcon from '~/ui/UiIcon/icons/LaptopIcon.vue';
import TriangleTopIcon from '~/ui/UiIcon/icons/TriangleTopIcon.vue';
import TriangleDownIcon from '~/ui/UiIcon/icons/TriangleDownIcon.vue';
import CompareIcon from '~/ui/UiIcon/icons/CompareIcon.vue';
import CompareCheckedIcon from '~/ui/UiIcon/icons/CompareCheckedIcon.vue';
import FacebookIcon from '~/ui/UiIcon/icons/FacebookIcon.vue';
import LinkedinIcon from '~/ui/UiIcon/icons/LinkedinIcon.vue';
import InstagramIcon from '~/ui/UiIcon/icons/InstagramIcon.vue';
import CartIcon from '~/ui/UiIcon/icons/CartIcon.vue';
import PlusIcon from '~/ui/UiIcon/icons/PlusIcon.vue';
import PostAddIcon from '~/ui/UiIcon/icons/PostAddIcon.vue';
import StarIcon from '~/ui/UiIcon/icons/StarIcon.vue';
import ArrowBottomIcon from '~/ui/UiIcon/icons/ArrowBottomIcon.vue';
import ArrowTopIcon from '~/ui/UiIcon/icons/ArrowTopIcon.vue';
import CheckboxIcon from '~/ui/UiIcon/icons/CheckboxIcon.vue';
import CheckboxOutlineIcon from '~/ui/UiIcon/icons/CheckboxOutlineIcon.vue';
import MainAccIcon from '~/ui/UiIcon/icons/MainAccIcon.vue';
import DragAndDropIcon from '~/ui/UiIcon/icons/DragAndDropIcon.vue';
import SettingsIcon from '~/ui/UiIcon/icons/SettingsIcon.vue';
import FilterIcon from '~/ui/UiIcon/icons/FilterIcon.vue';
import RadioIcon from '~/ui/UiIcon/icons/RadioIcon.vue';
import RadioOutlineIcon from '~/ui/UiIcon/icons/RadioOutlineIcon.vue';
import InformationIcon from '~/ui/UiIcon/icons/InformationIcon.vue';
import ChevronLeftIcon from '~/ui/UiIcon/icons/ChevronLeftIcon.vue';
import CopyIcon from '~/ui/UiIcon/icons/CopyIcon.vue';
import CheckIcon from '~/ui/UiIcon/icons/CheckIcon.vue';
import ColorPinIcon from '~/ui/UiIcon/icons/ColorPinIcon.vue';
import FileExportIcon from '~/ui/UiIcon/icons/FileExportIcon.vue';
import CertificateIcon from '~/ui/UiIcon/icons/CertificateIcon.vue';
import ListIcon from '~/ui/UiIcon/icons/ListIcon.vue';
import CloseIcon from '~/ui/UiIcon/icons/CloseIcon.vue';
import ClipIcon from '~/ui/UiIcon/icons/ClipIcon.vue';
import TrashIcon from '~/ui/UiIcon/icons/TrashIcon.vue';
import UnlockIcon from '~/ui/UiIcon/icons/UnlockIcon.vue';
import EditIcon from '~/ui/UiIcon/icons/EditIcon.vue';
import SwedbankLogo from '~/ui/UiIcon/icons/SwedbankLogo.vue';
import PrintIcon from '~/ui/UiIcon/icons/PrintIcon.vue';
import DoneIcon from '~/ui/UiIcon/icons/DoneIcon.vue';
import ClockIcon from '~/ui/UiIcon/icons/ClockIcon.vue';
import WarningIcon from '~/ui/UiIcon/icons/WarningIcon.vue';
import FileIcon from '~/ui/UiIcon/icons/FileIcon.vue';
import DownloadIcon from '~/ui/UiIcon/icons/DownloadIcon.vue';
import TrackIcon from '~/ui/UiIcon/icons/TrackIcon.vue';
import RDragIcon from '~/ui/UiIcon/icons/RDragIcon.vue';
import CoinsIcon from '~/ui/UiIcon/icons/CoinsIcon.vue';
import SuccessIcon from '~/ui/UiIcon/icons/SuccessIcon.vue';
import DownloadInBorderIcon from '~/ui/UiIcon/icons/DownloadInBorderIcon.vue';
import LocationIcon from '~/ui/UiIcon/icons/LocationIcon.vue';
import ScrollDownIcon from '~/ui/UiIcon/icons/ScrollDownIcon.vue';
import OfficeBuildingIcon from '~/ui/UiIcon/icons/OfficeBuildingIcon.vue';
import DeliveryIcon from '~/ui/UiIcon/icons/DeliveryIcon.vue';
import LogoutIcon from '~/ui/UiIcon/icons/LogoutIcon.vue';
import SearchIcon from '~/ui/UiIcon/icons/SearchIcon.vue';
import RefreshIcon from '~/ui/UiIcon/icons/RefreshIcon.vue';
import QuestionCircleIcon from '~/ui/UiIcon/icons/QuestionCircleIcon.vue';
import FacetimeIcon from '~/ui/UiIcon/icons/FacetimeIcon.vue';
import LinkIcon from '~/ui/UiIcon/icons/LinkIcon.vue';
import YoutubeIcon from '~/ui/UiIcon/icons/YoutubeIcon.vue';
import TwitterIcon from '~/ui/UiIcon/icons/TwitterIcon.vue';
import FacebookCircleIcon from '~/ui/UiIcon/icons/FacebookCircleIcon.vue';
import TeamsIcon from '~/ui/UiIcon/icons/TeamsIcon.vue';
import HorizontalBars from '~/ui/UiIcon/icons/HorizontalBars.vue';
import HorizontalToggleBars from '~/ui/UiIcon/icons/HorizontalToggleBars.vue';
import OrderOpenIcon from '~/ui/UiIcon/icons/OrderOpenIcon.vue';
import HandshakeIcon from '~/ui/UiIcon/icons/HandshakeIcon.vue';
import DepIcon from '~/ui/UiIcon/icons/DepIcon.vue';
import ImportIcon from '~/ui/UiIcon/icons/ImportIcon.vue';
import OffersIcon from '~/ui/UiIcon/icons/OffersIcon.vue';
import EyeIcon from '~/ui/UiIcon/icons/EyeIcon.vue';
import DateFromIcon from '~/ui/UiIcon/icons/DateFromIcon.vue';
import DateToIcon from '~/ui/UiIcon/icons/DateToIcon.vue';
import MenuIcon from '~/ui/UiIcon/icons/MenuIcon.vue';
import BackIcon from '~/ui/UiIcon/icons/BackIcon.vue';
import EyeHideIcon from '~/ui/UiIcon/icons/EyeHideIcon.vue';
import ChevronUpIcon from '~/ui/UiIcon/icons/ChevronUpIcon.vue';
import ArrowBottomMiniIcon from '~/ui/UiIcon/icons/ArrowBottomMiniIcon.vue';
import ArrowTopMiniIcon from '~/ui/UiIcon/icons/ArrowTopMiniIcon.vue';
import ArrowRightIcon from '~/ui/UiIcon/icons/ArrowRightIcon.vue';
import LinkedinBgIcon from '~/ui/UiIcon/icons/LinkedinBgIcon.vue';
import YoutubeBgIcon from '~/ui/UiIcon/icons/YoutubeBgIcon.vue';
import TwitterBgIcon from '~/ui/UiIcon/icons/TwitterBgIcon.vue';
import FacebookBgIcon from '~/ui/UiIcon/icons/FacebookBgIcon.vue';
import CalculatorIcon from '~/ui/UiIcon/icons/CalculatorIcon.vue';

export const icons = {
  aliases: {
    telegram: TelegramIcon,
    skype: SkypeIcon,
    whatsapp: WhatsappIcon,
    teams: TeamsIcon,
    phone: PhoneIcon,
    mail: MailIcon,
    catalog: CatalogIcon,
    order: OrderIcon,
    account: AccountIcon,
    warranty: WarrantyIcon,
    starOutline: StarOutlineIcon,
    shop: ShopIcon,
    chevronRight: ChevronRightIcon,
    chevronDown: ChevronDownIcon,
    idCard: IdCardIcon,
    apple: AppleIcon,
    usbFlash: UsbFlashIcon,
    laptop: LaptopIcon,
    compare: CompareIcon,
    compareChecked: CompareCheckedIcon,
    triangleTop: TriangleTopIcon,
    triangleDown: TriangleDownIcon,
    facebook: FacebookIcon,
    linkedin: LinkedinIcon,
    instagram: InstagramIcon,
    cart: CartIcon,
    plus: PlusIcon,
    postAdd: PostAddIcon,
    star: StarIcon,
    arrowBottom: ArrowBottomIcon,
    arrowTop: ArrowTopIcon,
    checkbox: CheckboxIcon,
    checkboxOutline: CheckboxOutlineIcon,
    mainAcc: MainAccIcon,
    dragAndDrop: DragAndDropIcon,
    settings: SettingsIcon,
    filter: FilterIcon,
    radio: RadioIcon,
    radioOutline: RadioOutlineIcon,
    info: InformationIcon,
    chevronLeft: ChevronLeftIcon,
    copy: CopyIcon,
    check: CheckIcon,
    colorPin: ColorPinIcon,
    fileExport: FileExportIcon,
    certificate: CertificateIcon,
    list: ListIcon,
    close: CloseIcon,
    clip: ClipIcon,
    trash: TrashIcon,
    unlock: UnlockIcon,
    edit: EditIcon,
    swedbankLogo: SwedbankLogo,
    print: PrintIcon,
    clockIcon: ClockIcon,
    doneIcon: DoneIcon,
    warning: WarningIcon,
    fileIcon: FileIcon,
    trackIcon: TrackIcon,
    downloadIcon: DownloadIcon,
    rdrag: RDragIcon,
    coins: CoinsIcon,
    success: SuccessIcon,
    downloadInBorder: DownloadInBorderIcon,
    location: LocationIcon,
    scrollDown: ScrollDownIcon,
    officeBuilding: OfficeBuildingIcon,
    delivery: DeliveryIcon,
    logout: LogoutIcon,
    search: SearchIcon,
    refresh: RefreshIcon,
    questionCircle: QuestionCircleIcon,
    facetime: FacetimeIcon,
    link: LinkIcon,
    youtube: YoutubeIcon,
    twitter: TwitterIcon,
    facebookCircle: FacebookCircleIcon,
    horizontalBars: HorizontalBars,
    horizontalToggleBars: HorizontalToggleBars,
    orderOpen: OrderOpenIcon,
    handshake: HandshakeIcon,
    dep: DepIcon,
    import: ImportIcon,
    offers: OffersIcon,
    eye: EyeIcon,
    dateFrom: DateFromIcon,
    dateTo: DateToIcon,
    menu: MenuIcon,
    back: BackIcon,
    eyeHide: EyeHideIcon,
    chevronUp: ChevronUpIcon,
    arrowBottomMini: ArrowBottomMiniIcon,
    arrowTopMini: ArrowTopMiniIcon,
    arrowRight: ArrowRightIcon,
    linkedinBg: LinkedinBgIcon,
    youtubeBg: YoutubeBgIcon,
    twitterBg: TwitterBgIcon,
    facebookBg: FacebookBgIcon,
    calculator: CalculatorIcon,
  },
};
