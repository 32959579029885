import useUserGeo from '~/infrastructure/composables/useUserGeo.ts';
import { computed, type ComputedRef } from 'vue';
import { type AppNavbarButtonProps, usePermissions } from '~/infrastructure';
import { useCompareButton } from '~/domains/compare';
import { useBasketButton } from '~/domains/basket';
import { useAccountButton } from '~/domains/account';

interface UseAppNavbarMenuReturn {
  appNavbarButtons: ComputedRef<AppNavbarButtonProps[]>;
}

export default function useAppNavbarMenu(): UseAppNavbarMenuReturn {
  const { isGeoValid } = useUserGeo();
  const { showOrdering } = usePermissions();
  const {
    isVisibleAccountButton,
    accountButtonText,
    accountButtonColor,
    accountButtonLabel,
  } = useAccountButton();
  const { compareCount } = useCompareButton();
  const { basketCount, amountText } = useBasketButton();

  const appNavbarButtons = computed(() => [
    {
      id: 'RentalCalculator',
      visible: isGeoValid('b2b.asbisafrica.co.za'),
      label: 'header.info.rentalCalculator.label',
      icon: '$calculator',
      href: 'https://asbisfinancecalculator.rentworks.co.za',
    },
    {
      id: 'Account',
      visible: isVisibleAccountButton.value,
      label: accountButtonLabel.value,
      icon: '$coins',
      color: accountButtonColor.value,
      to: '/account',
      text: accountButtonText.value,
    },
    {
      id: 'Compare',
      visible: true,
      label:
        'components.productsDataTable.paginator.comparePanel.goToCompare.text',
      icon: '$compare',
      to: '/compare',
      count: compareCount.value,
    },
    {
      id: 'Basket',
      visible: showOrdering.value,
      label: 'page.basket.title',
      icon: '$cart',
      to: '/basket',
      count: basketCount.value,
      color: 'primary',
      text: amountText.value,
    },
  ]);

  return {
    appNavbarButtons,
  };
}
