import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { type Basket } from '../../entities';
import { useBasketStatsStore } from './';
import { type DivideBaskets } from '~/domains/basket';
import { type LicenceType } from '~/domains/core';

export default defineStore('basket', () => {
  const baskets: Ref<DivideBaskets | undefined> = ref();
  const lastAddedLicenseType: Ref<LicenceType | undefined> = ref();
  const loading = ref(false);

  const { updateBasketStats } = useBasketStatsStore();

  const updateBasketsData = (newBaskets: DivideBaskets | undefined): void => {
    baskets.value = newBaskets;
  };

  const updateLastAddedLicenseType = (payload?: LicenceType): void => {
    lastAddedLicenseType.value = payload;
  };

  const updateBasket = (basket: Basket): void => {
    updateBasketsData(basket.baskets);
    updateBasketStats(basket.details);
  };

  const setLoading = (value: boolean): void => {
    loading.value = value;
  };

  const clearBasketLines = (): void => {
    updateBasketsData(undefined);
  };

  return {
    baskets,
    loading,
    lastAddedLicenseType,
    setLoading,
    updateBasket,
    updateLastAddedLicenseType,
    clearBasketLines,
    updateBasketsData,
  };
});
