<template>
  <UiButton
    size="large"
    :variant="variant"
    :rounded="rounded"
    :to="to"
    :href="href"
    :color="color"
    :target="href ? '_blank' : '_self'"
    class="button"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <UiLabel
      v-if="lgAndUp"
      :color="color"
      :label="label"
      :text="text"
      class="ml-1"
    />
  </UiButton>
</template>

<script setup lang="ts">
import UiButton, { type Variant } from './UiButton.vue';
import UiLabel from '../UiLabel/UiLabel.vue';
import { type Rounded } from '~/infrastructure';
import { useDisplay } from 'vuetify';

export interface Props {
  label: string;
  text?: string;
  filled?: boolean;
  variant?: Variant;
  rounded?: Rounded;
  to?: string;
  color?: string;
  href?: string;
}

defineProps<Props>();

const { lgAndUp } = useDisplay();
</script>

<style lang="scss" scoped>
.button {
  :deep(.v-btn__prepend) {
    @media (max-width: 1279px) {
      margin: inherit;
    }
  }
}
</style>
