<template>
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.0001 0.350098C1.53654 0.350098 0.350098 1.53654 0.350098 3.0001V17.0001C0.350098 18.4637 1.53654 19.6501 3.0001 19.6501H11.0001C12.4637 19.6501 13.6501 18.4637 13.6501 17.0001V3.0001C13.6501 1.53654 12.4637 0.350098 11.0001 0.350098H3.0001ZM1.6501 3.0001C1.6501 2.25451 2.25451 1.6501 3.0001 1.6501H11.0001C11.7457 1.6501 12.3501 2.25451 12.3501 3.0001V17.0001C12.3501 17.7457 11.7457 18.3501 11.0001 18.3501H3.0001C2.25451 18.3501 1.6501 17.7457 1.6501 17.0001V3.0001ZM11.0001 6.0001H3.0001V4.0001H11.0001V6.0001ZM5.0001 10.0001H3.0001V9.0001H5.0001V10.0001ZM3.0001 13.0001H5.0001V12.0001H3.0001V13.0001ZM5.0001 16.0001H3.0001V15.0001H5.0001V16.0001ZM6.0001 10.0001H8.0001V9.0001H6.0001V10.0001ZM8.0001 13.0001H6.0001V12.0001H8.0001V13.0001ZM6.0001 16.0001H8.0001V15.0001H6.0001V16.0001ZM11.0001 10.0001H9.0001V9.0001H11.0001V10.0001ZM9.0001 13.0001H11.0001V12.0001H9.0001V13.0001ZM11.0001 16.0001H9.0001V15.0001H11.0001V16.0001Z"
      fill="currentColor"
    />
  </svg>
</template>
