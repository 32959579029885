import { computed, type ComputedRef } from 'vue';
import { useCompareStore } from '~/domains/compare';
import { storeToRefs } from 'pinia';
import { getShortBadge } from '~/utils/badge';

interface UseCompareButtonReturn {
  compareCount: ComputedRef<string>;
}

export default function useCompareButton(): UseCompareButtonReturn {
  const { count } = storeToRefs(useCompareStore());

  const compareCount = computed(() =>
    count.value === 0 ? '' : getShortBadge(count.value, 999),
  );

  return { compareCount };
}
