import { productSerializer } from '~/domains/product';
import { SEPARATOR_HEADER_ROW } from '~/domains/product/constants.d';
import { dateSerializer } from '~/domains/core';
import {
  type BasketTypeResponse,
  type BasketLine,
  type DivideBaskets,
  getFilteredBaskets,
  BASKETS_TYPE_MAP,
} from '~/domains/basket';

const oneTypeBasketsSerializer = (
  basketsByOneType: BasketTypeResponse[],
): any => {
  const products: any[] = [];

  basketsByOneType.forEach((basket) => {
    if (basket.lines === undefined) return;

    products.push({
      section: SEPARATOR_HEADER_ROW,
      sku: basket.owner.full_name,
    });

    basket.lines.forEach((line) => {
      let reserved = {};
      const result: BasketLine = {
        ...productSerializer(line.product),
        quantity: {
          ordered: line.qty === undefined ? 1 : +line.qty,
        },
      };

      if (line.id !== undefined) {
        result.lineId = line.id;
      }

      if (line.arrival_date !== undefined) {
        result.lineArrivalDate = dateSerializer(line.arrival_date);
      }

      if (line.reserved_qty !== undefined) {
        reserved = {
          ...reserved,
          reservedCount: +line.reserved_qty,
        };
      }

      if (line.reserve_ttl_minutes !== undefined) {
        reserved = {
          ...reserved,
          reserveExpirationMinutes: +line.reserve_ttl_minutes,
        };
      }

      if (line.reserve_status !== undefined) {
        reserved = {
          ...reserved,
          reserveStatus: line.reserve_status,
        };
      }

      if (line.reserve_result !== undefined) {
        reserved = {
          ...reserved,
          reserveResult: line.reserve_result,
        };
      }

      products.push({
        ...result,
        ...(Object.keys(reserved).length !== 0 && { reserved }),
      });
    });
  });

  return products;
};

const divideBasketsByType = (
  baskets: BasketTypeResponse[] | undefined,
): DivideBaskets => {
  /**
   * Divides baskets by their type ('ESD', 'NOT_A_LICENSE', 'NOT_A_LICENSE_MARGINAL_VAT').
   *
   * @param baskets - An array of BasketTypeResponse objects or undefined.
   * @returns An object where keys are basket types ('basketWithProducts', 'basketWithLicenceProducts', 'basketWithMarginVatProducts')
   * and values are arrays of BasketLine objects corresponding to each type.
   */

  const result: DivideBaskets = {};

  for (const key of Object.keys(BASKETS_TYPE_MAP)) {
    const basketType = key as keyof typeof BASKETS_TYPE_MAP;
    result[basketType] =
      baskets === undefined
        ? []
        : oneTypeBasketsSerializer(
            getFilteredBaskets(baskets, BASKETS_TYPE_MAP[basketType].type),
          );
  }

  return result;
};

export default function basketProductsSerializer(
  baskets: BasketTypeResponse[] | undefined,
): DivideBaskets {
  return divideBasketsByType(baskets);
}
