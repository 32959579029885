import { useAvailableCredit } from '~/domains/user/composables/useAvailableCredit.ts';
import { computed, type ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import { usePermissions } from '~/infrastructure';

interface UseAccountButtonReturn {
  accountButtonLabel: ComputedRef<string>;
  accountButtonColor: ComputedRef<string>;
  accountButtonText: ComputedRef<string>;
  isVisibleAccountButton: ComputedRef<boolean>;
}

export default function useAccountButton(): UseAccountButtonReturn {
  const { showOrdering, showAccount } = usePermissions();
  const { availableCreditContent, overdueIsMoreZero, isCreditLimit } =
    useAvailableCredit();
  const { user } = storeToRefs(useUserStore());
  const currentCurrency = computed(() => user.value?.selected.currency);

  const accountButtonLabel = computed(() =>
    overdueIsMoreZero.value
      ? 'header.info.overdue.label'
      : 'header.info.availableCredit.label',
  );

  const accountButtonColor = computed(() =>
    overdueIsMoreZero.value ? 'red' : 'grey-600',
  );

  const accountButtonText = computed(
    () => `${availableCreditContent.value} ${currentCurrency.value}`,
  );

  const isVisibleAccountButton = computed(
    () => isCreditLimit.value && showAccount.value && showOrdering.value,
  );

  return {
    accountButtonLabel,
    accountButtonColor,
    accountButtonText,
    isVisibleAccountButton,
  };
}
