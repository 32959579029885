import { computed, type ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import useUserStore from '../../domains/user/user.store.ts';
import { USER_GEO, type UserGeo } from '~/infrastructure';

interface UseUserGeoReturn {
  currentGeo: ComputedRef<string | undefined>;
  isGeoValid: (id: UserGeo) => boolean;
}

export default (): UseUserGeoReturn => {
  const { user } = storeToRefs(useUserStore());
  const currentGeo = computed(() => user.value?.selected?.shopId?.toString());

  const isGeoValid = (id: UserGeo): boolean =>
    currentGeo.value === USER_GEO[id];

  return {
    currentGeo,
    isGeoValid,
  };
};
