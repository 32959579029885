import authRepository from './auth.repository';
import {
  type LogoutStatus,
  type LoginFormData,
  type LoginResult,
  type PingStatus,
  type ForgotPasswordFormData,
  type ForgotPasswordStatus,
  type ResetPasswordForm,
  type ResetPasswordStatus,
  type Link,
} from './types.d';
import useUserStore from '~/domains/user/user.store';
import { useCompareStore } from '~/domains/compare';
import { useBasketStatsStore, useBasketStore } from '~/domains/basket';
import { useAuthStore, PATH_DICTIONARY } from '~/domains/auth';

const authService = {
  async login(loginForm: LoginFormData): Promise<LoginResult> {
    const { setAuthToken } = useAuthStore();

    try {
      const response = await authRepository.login(loginForm);
      if (
        response.status === 'NEED_TO_CHANGE_PASSWORD' &&
        response.token !== undefined
      ) {
        setAuthToken(response.token);
      }

      if (
        response.status !== 'OK' &&
        response.status !== 'NEED_TO_CHANGE_PASSWORD'
      )
        return response;

      if (response.token === undefined) throw new Error('There is no token');

      setAuthToken(response.token);

      return response;
    } catch (error) {
      console.error(error);
      return {
        status: 'INTERNAL_SERVER_ERROR',
      };
    }
  },

  async logout(): Promise<LogoutStatus> {
    const store = useUserStore();
    const { clearAuthToken, setRedirectAfterLogin } = useAuthStore();
    const { clearCompare } = useCompareStore();
    const { clearBasketStats } = useBasketStatsStore();
    const { clearBasketLines } = useBasketStore();

    try {
      const response = await authRepository.logout();

      clearAuthToken();
      localStorage.removeItem('isNeedToChangePassword');
      clearCompare();
      clearBasketStats();
      clearBasketLines();
      store.setUser(null);
      setRedirectAfterLogin(PATH_DICTIONARY.home);

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async ping(): Promise<PingStatus> {
    const { clearAuthToken } = useAuthStore();
    try {
      const response = await authRepository.ping();

      if (response.status !== 'OK') {
        clearAuthToken();
      }

      return response.status;
    } catch (error) {
      clearAuthToken();

      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async forgotPassword(
    forgotForm: ForgotPasswordFormData,
  ): Promise<ForgotPasswordStatus> {
    try {
      const formData = new FormData();

      formData.append('username', forgotForm.login);
      formData.append('email', forgotForm.email);

      const response = await authRepository.forgotPassword(formData);

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async resetPassword(
    resetForm: ResetPasswordForm,
    token: string,
  ): Promise<ResetPasswordStatus> {
    try {
      const formData = new FormData();

      formData.append('username', resetForm.login);
      resetForm.email !== undefined &&
        formData.append('email', resetForm.email);
      formData.append('password', resetForm.password);
      formData.append('token', token);

      const response = await authRepository.resetPassword(formData);

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async getBecomeACustomerLink(): Promise<Link | undefined> {
    try {
      return await authRepository.getBecomeACustomerLink();
    } catch (error) {
      console.error(error);
    }
  },
};

export default authService;
