import api from '~/infrastructure/api/api.service';
import { BASKET, BASKET_STATISTICS, BASKET_TOP } from '../../routes';
import { type Basket, type BasketStatistics } from '../../entities';
import {
  type BasketResponse,
  type BasketStatisticsResponse,
  type BasketTopProductsResponse,
  type BasketUpdateMode,
} from '../../types';

import {
  basketStatisticsSerializer,
  basketUpdateDeserializer,
  basketSerializer,
} from '../serializers';
import { type Product, type ProductResponse } from '~/domains/product';
import { productSerializer } from '~/domains/product';

export default {
  async getBasket(): Promise<Basket> {
    const response: BasketResponse = await api(BASKET, {
      method: 'GET',
    });

    return basketSerializer(response);
  },

  async updateBasket(
    body: Product[],
    options: {
      mode: BasketUpdateMode;
    },
  ): Promise<Basket> {
    const response = await api<BasketResponse>(BASKET, {
      method: 'POST',
      body: basketUpdateDeserializer(body, options.mode),
    });

    return basketSerializer(response);
  },

  async getBasketStatistics(): Promise<BasketStatistics> {
    const response: BasketStatisticsResponse = await api(BASKET_STATISTICS, {
      method: 'GET',
    });

    return basketStatisticsSerializer(response);
  },

  async getTopProducts(): Promise<Product[] | null> {
    const response: BasketTopProductsResponse = await api(BASKET_TOP, {
      method: 'GET',
    });

    return (
      response.products?.map((product: ProductResponse) =>
        productSerializer(product),
      ) ?? null
    );
  },
};
